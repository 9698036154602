.main-container {
    margin: 20px 10%;
    display: flex;
    justify-self: center;
    min-height: 400px;
    width: 80%;
}

.card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.card-container span {
    text-align: left !important;
    color: var(--black, #282828);
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.card-list {
    display: flex;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    align-self: center;
    gap: 20px;
    width: 100%;
    max-height: 1837px;
}

@media (max-width: 940px) {
    .main-container {
        height: auto;
    }

    .card-container {
        margin: 10px;
        align-items: center;
    }

    .card-list {
        overflow-y: auto;
        justify-content: center;
        height: auto;
        padding-bottom: 20px;
    }

    .card-container span {
        margin: 0px 10px;
        text-align: center;
    }
}

@media (min-width: 940px) and (max-width: 1410px) {
    .card-list {
        max-width: 740px;
    }

    .card-container {
        align-items: center;
    }
}

@media (min-width: 1410px) {
    .main-container {
        justify-content: center;
    }
    .card-container {
        max-width: 1200px;
        align-self: center;
    }
}