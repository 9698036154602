.btn {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 5;
    border: none;
    cursor: pointer;
    background-image: url("../up.png");
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: center;
    width: 52px;
    height: 52px;
    border-radius: 20px;
    background-color: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    padding: 12px;
    transition: opacity 0.3s, bottom 0.3s;
}

@media (max-width: 600px) {
    .btn {
        width: 40px;
        height: 40px;
        right: 20px;
        bottom: 20px;
        background-size: 28px 28px;
    }
}