@import url('https://fonts.cdnfonts.com/css/montserrat');

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #0E86F8;
    width: 100%;
    box-sizing: border-box;
    gap: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 120px;
    max-height: 200px;
    margin-top: 5px;
    box-shadow: 0px 0px 2px 5px rgba(55, 55, 116, 0.15);
}

.footerRow:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    gap: 8px;
}

.footerRow:nth-child(1) span:nth-child(1) {
    font-family: "Montserrat";
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
}

.footerRow:nth-child(1) span:nth-child(2) {
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
}

.footerRow:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.footerRow:nth-child(2) a {
    text-decoration: none;
    color: white;
    font-family: "Montserrat";
    font-size: 16px;
}

@media (max-width: 700px) {
    .footer {
        padding-top: 10px;
        padding-bottom: 10px;
        gap: 0px;
    }
}