body {
    margin: 0;
}

.container {
    justify-content: center;
    display: block;
}

.licensePage {
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    z-index: 3;
    margin: 0 auto;
    margin-left: 220px;
    min-height: 300px;
    /* height: calc(100vh - 402px + 202px); */
    overflow-y: auto;
}

.agreementPage {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    height: calc(100vh - 120px);
    overflow-y: auto;
    background: var(--white, #FAFAFA);
}

.centeredHeader {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.centeredParagraph {
    text-align: center;
    font-weight: bold;
}

.paragraph {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.boldText {
    font-weight: bold;
    margin-top: 10px;
}

.downloadButton {
    align-self: center;
    margin: 20px;
    display: flex;
    width: 248px;
    height: 56px;
    padding: 5px 18px;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);
    color: var(--light-blue, #3CADFF);

    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
}

.downloadButton:hover {
    color: white;
    border-radius: 16px;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
    cursor: pointer;
}

@media (max-width: 700px) {
    .licensePage {
        margin-left: 0px;
        height: calc(100vh - 360px);
    }
}

.addPadding {
    padding-left: 40px;
}