@import url('https://fonts.cdnfonts.com/css/montserrat');

* {
    /* ST2 */
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    padding-left: 60px;
    padding-right: 60px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    max-height: 10vh;
    gap: 80px;
}

.app-header .left-section {
    display: block;
    text-align: center;
    max-width: 200px;
    cursor: pointer;
}

.app-header .logo {
    width: 183px;
    height: auto;
}

.app-header .left-section hr {
    border: none;
    width: 183px;
    height: 1px;
    color: #333;
    background-color: #333;
}

.app-header .left-section span {
    color: var(--black, #282828);
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.96px;
    text-transform: uppercase;
}

.app-header .right-section {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.app-header .right-section .button {
    display: flex;
    width: 248px;
    height: 56px;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    font-size: 18px;
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);

    color: var(--light-blue, #3CADFF);
    text-decoration: none
}

.app-header .button:hover {
    transition: 200ms;
    color: white;
    border-radius: 16px;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
    cursor: pointer;
}

@media (max-width: 700px) {
    .app-header {
        display: flex;
        justify-content: center;
        gap: 0px;
    }

    .app-header .right-section .button {
        display: none;
    }
}