.navigation-menu {
    position: absolute;
    display: inline-flex;
    width: 220px;

    padding-top: 30px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-right: 1px solid var(--gray, #ACACAC);
    /* height: 80vh; */
}

.navigation-menu .logo {
    color: var(--black, #282828);
}

.logo span {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 195px;
    max-height: 40px;
    border-radius: 5px;
    border: 1px solid;
    border-color: #AAA;
}

.search img {
    border: 1px solid;
    border-color: #AAA;
    padding: 10px;
    border-radius: 5px;
    margin-right: 4px;
    /* margin-left: 10px; */
}

input {
    padding: 5px;
    width: 138px;
    width: 99%;
    border: none;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    background: var(--light-gray, #EEE);
    height: auto;
    overflow-x: hidden;
}

.links a {
    text-decoration: none;
    color: var(--black, #282828);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 56px;
    height: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    width: 86%;
}

.links a:hover {
    background: rgba(60, 173, 255, 0.20);
}

@media (max-width: 700px) {
    .navigation-menu {
        display: none;
    }
}

@media (max-height: 1000px) {
    .links {
        overflow-y: auto;
    }

    .links a {
        width: 84%;
    }
}