.documentationSection {
    margin-bottom: 30px;
    border-radius: 5px;
    padding: 20px;
}

.endpoint {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #333;
}

.method {
    font-weight: bold;
    color: #555;
}

.description {
    margin-bottom: 15px;
    color: #777;
}

.codeBlock {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #e9e9e9;
    border-radius: 4px;
    font-family: monospace;
    white-space: pre-wrap;
    overflow-x: auto;
}

.fieldList {
    padding: 0;
}

.fieldItem {
    margin-bottom: 15px;
}

.fieldName {
    font-weight: bold;
    color: #333;
}

.required {
    color: red;
    margin-left: 5px;
}

.choices {
    margin-top: 5px;
    color: #666;
}

.maxLength,
.minValue {
    margin-top: 5px;
    color: #666;
}

.subfieldsList {
    margin-top: 10px;
    padding-left: 20px;
}

.subfieldItem {
    margin-bottom: 10px;
}

.subfieldName {
    font-weight: bold;
    color: #333;
}

.subfieldMaxLength,
.subfieldMinValue {
    margin-top: 5px;
    color: #666;
}