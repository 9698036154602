.card {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 20px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 320px;
}

.card h3 {
    margin-bottom: 10px;
    color: var(--black, #282828);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card p {
    margin: 0;
    text-align: justify;
    color: var(--black, #282828);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card:hover {
    border-radius: 20px;
    background: linear-gradient(0deg, rgba(60, 173, 255, 0.20) 0%, rgba(60, 173, 255, 0.20) 100%), #FFF;
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    cursor: pointer;
    transition: all 0.2s ease-in;
}